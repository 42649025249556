<template>
  <el-form ref="form" :model="formData" :rules="formRules" label-position="top">
    <el-form-item label="請輸入個案姓名" prop="subsidyMember">
      <SubsidyMemberSearch
        data-cy="member-select"
        :model.sync="formData.subsidyMember"
        placeholder="請輸入個案姓名"
        @change="onSubsidyMemberChange"
      />
    </el-form-item>
    <el-form-item v-if="subsidyMember.address.length > 0" label="個案地址" prop="userAddress">
      <el-radio-group v-model="formData.userAddress" class="user-address">
        <el-radio v-for="address in subsidyMember.address" :key="address" :label="address" border>{{ address }}</el-radio>
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>

<script>
import SubsidyMemberSearch from '@/components/Search/SubsidyMemberSearch.vue'
import {noEmptyRules} from '@/validation'
import {FindSubsidyMember} from '@/api/subsidyMember'

export default {
  name: 'SubsidyMember',
  components: {
    SubsidyMemberSearch,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        allowOtherReservation: false,
      }),
    },
    fixedMember: {
      type: String,
      default: '',
    },
  },
  setup(props, {emit}) {
  },
  data: () => ({
    subsidyMember: {
      address: [],
    },
    formData: {
      subsidyMember: null,
      userAddress: ''
    },
    formRules: {
      userAddress: [noEmptyRules()],
    },
  }),
  computed: {
    shopId() {
      return this.$store.getters.shop
    },
  },
  watch: {
    formData: {
      handler(data) {
        this.$emit('update', data)
      },
      deep: true,
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.$emit('ref', this.$refs.form)
    })

    if (this.fixedMember) {
      await this.findSubsidyMember(this.fixedMember)
    }
  },
  methods: {
    async findSubsidyMember(memberId) {
      if (!memberId) return
      try {
        this.formData.member = await FindSubsidyMember({
          shopId: this.shopId,
          id: memberId,
        })
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    onSubsidyMemberChange(member) {
      this.subsidyMember = member
    }
  },
}
</script>

<style scoped>
.user-address {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .el-radio.is-bordered {
    padding: 12px 20px !important;
    height: auto !important;
  }

  .el-radio {
    margin-right: 0;
  }

  .el-radio.is-bordered+.el-radio.is-bordered {
    margin-left: 0;
  }
}
</style>
