import { render, staticRenderFns } from "./SubsidyMember.vue?vue&type=template&id=28049f41&scoped=true"
import script from "./SubsidyMember.vue?vue&type=script&lang=js"
export * from "./SubsidyMember.vue?vue&type=script&lang=js"
import style0 from "./SubsidyMember.vue?vue&type=style&index=0&id=28049f41&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28049f41",
  null
  
)

export default component.exports