<template>
  <div class="reservation-create">
    <!-- <section class="card-container wrapper"> -->
    <div class="font-medium text-xl text-gray-100 cursor-pointer flex items-center" @click="router.go(-1)">
      <span class="material-icons cursor-pointer" style="margin-right: 9px;">chevron_left</span>
      新增預約
    </div>
    <div class="side-highlight-container">
      <MemberInfo
        :fixedMember="queryMember"
        :config="configData"
        :memberClassTickets.sync="memberClassTickets"
        @ref="getRef($event, 'memberInfo')"
        @update="dataUpdate($event, 'memberData')"
      />
      <OtherUser
        v-if="peopleCount > 1"
        :peopleCount="peopleCount"
        @update="dataUpdate($event, 'otherUserData')"
      />
    </div>

    <div class="side-highlight-container">
      <ServiceInfo
        :config="configData"
        :peopleCount="peopleCount"
        @ref="getRef($event, 'serviceInfo')"
        @update="dataUpdate($event, 'mainServiceData')"
      />
      <div v-if="canOrderOtherService">
        <div v-if="!mainServiceData.date || !mainServiceData.time" class="text-sub text-gray-100 mb-[16px]">請先選擇預約日期與時段</div>
        <OtherService
          v-if="mainServiceData.date && mainServiceData.time"
          :date="mainServiceData.date"
          :config="configData"
          :peopleCount="peopleCount"
          :mainServiceData="mainServiceData"
          @ref="getRef($event, 'otherService')"
          @update="dataUpdate($event, 'otherServiceData')"
        />
      </div>
    </div>

    <!-- For智慧城鄉 -->
    <div class="side-highlight-container">
      <SubsidyMember
        :fixedMember="queryMember"
        :config="configData"
        @ref="getRef($event, 'subsidyMember')"
        @update="dataUpdate($event, 'subsidyMember')"
      />
    </div>

    <!-- ANCHOR 付款 -->
    <div v-if="enabledBlocks.deposit || enabledBlocks.serviceClassTicket" class="side-highlight-container flex flex-col" style="gap: 24px">
      <p class="block-title">預約付款資訊</p>
      <p v-show="!showServiceClassTicket && !showDepositBlock" class="text-sub text-gray-60">請先填寫預約資訊</p>
      <ServiceClassTicket
        v-if="enabledBlocks.serviceClassTicket && showServiceClassTicket"
        :mainServiceData.sync="mainServiceData"
        :otherServiceData.sync="otherServiceData"
        :memberClassTickets="memberClassTickets"
        @ref="getRef($event, 'classTicket')"
      />

      <!-- ANCHOR 訂金 -->
      <Deposit
        v-if="showDepositBlock && enabledBlocks.deposit"
        :memberData="memberData"
        :orderDeposit="orderDeposit"
        :model.sync="depositPayment"
        :depositConfig="depositConfig"
        :depositPaymentConfig="depositPaymentConfig"
        :isUseClassTicket="isUsingClassTicket"
        @ref="getRef($event, 'deposit')"
      />
    </div>

    <div class="side-highlight-container">
      <Note
        :data="compactOrder"
        @ref="getRef($event, 'note')"
        @update="dataUpdate($event, 'noteData')"
      />
    </div>

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.go(-1)"
      @confirm="createReservationOrder"
    />
    <!-- </section> -->
  </div>
</template>

<script>
import reservationMixin from '@/mixin/reservation'
import { CreateReservationOrder, SmartCreateReservationOrder } from '@/api/reservation'
import MemberInfo from './components/MemberInfo.vue'
import OtherUser from './components/OtherUser.vue'
import ServiceInfo from './components/ServiceInfo.vue'
import OtherService from './components/OtherService.vue'
import ServiceClassTicket from './components/ServiceClassTicket.vue'
import Deposit from './components/Deposit.vue'
import Note from './components/Note.vue'
import SubsidyMember from './components/SubsidyMember.vue'
import formUtils from '@/utils/form'
import { get, isEmpty, filter } from 'lodash'
import debounce from 'lodash.debounce'
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useReservation } from '@/use/useReservation'
import { useRouter } from 'vue-router/composables'
import { mapGetters, mapActions } from 'vuex'

export default defineComponent({
  name: 'ReservationCreate',
  components: {
    MemberInfo,
    ServiceInfo,
    OtherService,
    ServiceClassTicket,
    // SelectServiceClassTicket,
    Deposit,
    Note,
    OtherUser,
    SubsidyMember,
  },
  mixins: [reservationMixin],

  setup (props, { emit }) {
    const { configData, getAllConfig, enabledBlocks } = useReservation()
    const router = useRouter()
    const memberClassTickets = ref([])

    onMounted(async () => {
      getAllConfig()
    })

    return { configData, memberClassTickets, enabledBlocks, router }
  },
  computed: {
    isUsingClassTicket () {
      if (get(this.mainServiceData, 'classTicket')) return true
      if (!isEmpty(filter(this.otherServiceData, service => service.classTicket))) return true
      return false
    },
  },

  watch: {
    async form (form) {
      console.log('watch', form)
    },
  },
  async mounted () {
    await this.getShopReservationConfig({ shopId: this.shopId })
  },
  methods: {
    ...mapActions('shop', [
      'getShopReservationConfig',
    ]),
    get,
    async dataUpdate (data, type) {
      if (type === 'memberData') this.memberData = data
      else if (type === 'mainServiceData') {
        if (get(this.mainServiceData, 'time') !== data.time) this.otherServiceData = []
        this.mainServiceData = data
      } else if (type === 'otherServiceData') this.$set(this.otherServiceData, 0, data)
      else if (type === 'otherUserData') this.otherUserData = data
      else if (type === 'noteData') this.noteData = data
      else if (type === 'subsidyMember') this.subsidyMember = data
    },

    createReservationOrder: debounce(async function () {
      if (this.loading) return
      this.loading = true

      for (const form of Object.keys(this.form)) {
        if (this.form[form]) {
          if (!await formUtils.checkForm(this.form[form])) {
            this.loading = false
            return
          }
        }
      }

      const isMember = this.memberData.isMember
      const memberName = isMember ? get(this.memberData, 'member.UserInfo.name') : this.memberData.name
      const memberPhone = isMember ? get(this.memberData, 'member.UserInfo.phone') : this.memberData.phone
      let depositPayment = this.depositPayment
      let depositComment = null
      if (this.depositPayment.includes('offline')) {
        depositPayment = 'offline'
        depositComment = this.depositPayment
      }
      const [, err] = await SmartCreateReservationOrder({
        shopId: this.shopId,
        reservations: this.compactReservation,
        userType: isMember ? 'member' : 'guest',
        memberId: isMember ? get(this.memberData, 'member.id', undefined) : undefined,
        userName: memberName,
        userPhone: memberPhone,
        userAddress: get(this.subsidyMember, 'userAddress', undefined),
        peopleCount: this.peopleCount,
        userComment: get(this.noteData, 'comment') || undefined,
        adminComment: get(this.noteData, 'remark') || undefined,
        depositPrice: this.showDepositBlock ? this.orderDeposit.price : undefined,
        depositPaidType: this.showDepositBlock ? depositPayment : undefined,
        depositPaidTypeComment: depositComment || undefined,
        // remark: get(this.noteData, 'remark', undefined),
      })
      if (err) {
        this.loading = false
        this.$message.error(err)
        return
      }

      this.$message.success('預約成功')
      this.$router.go(-1)
      this.loading = false
    }, 300, { leading: false, trailing: true }),

  },
})
</script>

<style scoped lang="postcss">
.reservation-create {
  @apply flex flex-col gap-[30px];
}
.wrapper {
  padding: 20px !important;
  padding-left: 29px !important;
}

.block-title {
  @apply font-bold text-primary-100 text-[18px];
}
</style>
