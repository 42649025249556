<template>
  <div class="service-info">
    <p class="font-bold text-lg text-primary-100 mb-[24px]">預約資訊</p>
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item v-if="checkResource" label="服務性質" prop="type">
        <el-select
          v-model="formData.type"
          value-key="id"
          placeholder="請選擇"
          no-data-text="暫無數據"
          @change="serviceTypeChange"
        >
          <el-option
            v-for="item in showServiceTypeConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="showHumanService" label="服務車次" prop="serviceUnit">
        <template slot="label">
          <FormItemTooltipLabel label="服務車次" :tooltipWidth="200" style="display: flex !important">
            <ol>
              <li>1. 由所有服務車次分配：由分店內所有服務車次分配</li>
              <br>
              <li>2. 由不指定人員分配：由綁定在不指定人員中之服務車次分配</li>
            </ol>
          </FormItemTooltipLabel>
        </template>
        <div>
          <el-select
            v-model="formData.serviceUnit"
            value-key="id"
            filterable
            clearable
            @change="serviceUnitChange"
            @clear="serviceUnitChange"
          >
            <el-option
              v-for="unit in serviceUnitIOptions"
              :key="unit.id"
              :value="unit"
              :label="unit.name"
            />
          </el-select>
          <p
            v-show="serviceUnitSelectHint.content"
            :style="`color: ${serviceUnitSelectHint.color}`"
          >
            {{ serviceUnitSelectHint.content }}
          </p>
        </div>
      </el-form-item>
      <el-form-item label="服務項目" prop="service">
        <el-select
          v-model="formData.service"
          value-key="id"
          filterable
          :disabled="disabledInput.service"
          clearable
          @change="serviceChange"
          @clear="serviceChange"
        >
          <el-option
            v-for="service in filterServiceOptions"
            :key="service.id"
            :value="service"
            :label="service.name"
          />
        </el-select>
        <!-- <UnitServicesSelect
          data-cy="service-select"
          showAll
          :unitId="getData(formData.serviceUnit, 'id', '')"
          :model.sync="formData.service"
          :isResourceBind="showResourceService"
          @change="serviceChange"
        /> -->
      </el-form-item>
      <el-form-item v-if="enableSubService" label="子項目" prop="subService">
        <el-select
          v-model="formData.subService"
          value-key="id"
          :disabled="disabledInput.service"
          @change="subServiceChange"
        >
          <el-option
            v-for="service in subServiceList"
            :key="service.id"
            :label="service.name"
            :value="service"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="附加服務" prop="attachServices">
        <AttachServiceSelect
          data-cy="attach-service-select"
          :model.sync="formData.attachServices"
          :disabled="disabledInput.attachService"
          :services="formData.service"
          multiple
          @change="attachServiceChange"
        />
      </el-form-item>
      <el-form-item v-if="showResourceService && checkResource" label="服務設備" prop="resourceItemId">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>服務設備</p>
          </div>
        </template>
        <ResourceUnitSelect data-cy="unit-binding-select" :model.sync="formData.resourceItemId" :data="resourceItemList" :showAll="true" />
      </el-form-item>

      <el-form-item label="預估服務時數(分鐘)" prop="totalTime">
        <el-select
          v-model="formData.totalTime"
          data-cy="total-time-select"
          placeholder="請選擇"
          :disabled="disabledInput.totalBookingTime"
          @change="totalTimeChange"
        >
          <el-option
            v-for="(item, index) in genNums"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="showHumanAndResourceService && checkResource" label="預估設備使用時數(分鐘)" prop="resourceTotalTime">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>預估設備使用時數(分鐘)</p>
            <el-tooltip placement="right">
              <div slot="content">
                使用設備的起始時間與服務項目開<br>
                始時間一致。可調整此欄位的數<br>
                值，提前結束設備使用的時間
              </div>
              <span class="material-icons">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <div
          v-if="!formData.totalTime"
          class="text-gray-100"
        >
          請先選擇預估服務時數
        </div>
        <el-select
          v-else
          v-model="formData.resourceTotalTime"
          data-cy="total-time-select"
          placeholder="請選擇"
        >
          <el-option
            v-for="(item, index) in getResourceNums"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否為加班時段">
        <el-radio-group
          v-model="formData.isOverTime"
          :disabled="disabledInput.isOverTime"
          @change=";(formData.time = ''), (formData.date = '')"
        >
          <el-radio :label="true" data-cy="main-overTime-yes">是</el-radio>
          <el-radio :label="false" data-cy="main-overTime-no">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <hr style="margin-bottom: 16px">
      <el-form-item label="預約日期" prop="date">
        <div
          v-if="!formData.service || !formData.type"
          class="text-gray-100"
        >
          請先選擇服務性質與服務項目
        </div>
        <el-date-picker
          v-if="formData.service && formData.type"
          ref="picker"
          v-model="formData.date"
          data-cy="date-select"
          :picker-options="pickerOptions"
          type="date"
          placeholder="選擇日期"
          @focus="getDate"
          @change="dateChange"
        />
      </el-form-item>
      <el-form-item label="預約時段" prop="time">
        <div v-if="!formData.date" class="text-gray-100">請先選擇預約日期</div>
        <AvailableTimeSelect
          v-if="formData.date && !formData.isOverTime"
          :model.sync="formData.time"
          :timesList="availableTimes"
        />
        <!-- <ServiceUnitTimeSelect
          v-if="formData.date && !formData.isOverTime"
          :model.sync="formData.time"
          :date="formData.date"
          :unit="formData.serviceUnit"
          :totalTime="formData.totalTime"
        /> -->
        <el-select
          v-if="formData.date && formData.isOverTime"
          v-model="formData.time"
          placeholder="請選擇"
          no-data-text="無數據"
        >
          <el-option
            v-for="(item, index) in genTimes()"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="peopleCount === 1 && config.reservation.allowOtherReservation && formData.type === 'humanService'"
        label="是否有當日其他服務"
      >
        <el-radio-group v-model="formData.otherService">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <hr style="margin-bottom: 16px">
      <el-form-item label="當日有陪病者">
        <el-radio-group
          v-model="formData.hasPartner"
        >
          <el-radio :label="true" data-cy="main-overTime-yes">是</el-radio>
          <el-radio :label="false" data-cy="main-overTime-no">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UnitServicesSelect from '@/components/Select/UnitServicesSelect.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
// import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
// import ServiceUnitTimeSelect from '@/components/Select/ServiceUnitTimeSelect.vue'
import AttachServiceSelect from '@/components/Select/AttachServiceSelect.vue'
import AvailableTimeSelect from '@/components/Reservation/AvailableTimeSelect/AvailableTimeSelect.vue'
import ResourceUnitSelect from '@/components/Select/ResourceUnitSelect.vue'
import { mapGetters } from 'vuex'
import { computed, defineComponent, onMounted, ref, reactive, unref } from 'vue'
// api
import { GetAvailableTimes } from '@/api/reservation'
import { FindService, GetService } from '@/api/service'
import { FindServiceUnit, GetServiceUnit } from '@/api/serviceUnit'
// utils
import { generateTimes, generateNumbers, sortOrder } from '@/utils/helper'
import { setValues } from '@/utils/object'
import { getMonth, thisMonth } from '@/utils/date'
import { noEmptyRules } from '@/validation'
import { get, filter, map, find, omit, includes, isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { notSpecifyRulesConfig, noSpecifyUnitOrderOptionConfig, serviceTypeConfig } from '@/config/reservation'
import { checkUserFeature } from '@/store/modules/permission'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { useReservation } from '@/use/useReservation'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ServiceInfo',
  components: {
    // UnitServicesSelect,
    // ServicesUnitSelect,
    // ServiceUnitTimeSelect,
    FormItemTooltipLabel,
    ResourceUnitSelect,
    AvailableTimeSelect,
    AttachServiceSelect,
  },
  props: {
    peopleCount: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const { fetchAllOld } = useFetch()
    const { shopId } = useShop()
    const genNums = ref([])
    const getResourceNums = ref([])
    const allServiceUnitList = ref([])
    const allServiceList = ref([])
    const mainServiceData = ref(null)
    const serviceOptions = ref([])
    const serviceUnitIOptions = computed(() => {
      const base = map(allServiceUnitList.value, (serviceUnit) => {
        return {
          id: serviceUnit.id,
          name: serviceUnit.name,
        }
      })

      base.unshift({
        id: 'notSpecify',
        name: '由不指定人員分配',
      })
      base.unshift({
        id: 'all',
        name: '由所有服務車次分配',
      })
      return base
    })
    const serviceUnitSelectHint = computed(() => {
      const useNotSpecify = get(props.config, 'reservation.useNotSpecify')
      const notSpecifyRule = get(props.config, 'reservation.notSpecifyRule')
      const selectUnitId = get(formData.serviceUnit, 'id')
      if (!includes(['notSpecify', 'all'], selectUnitId)) return { content: '', color: 'var(--base)' }

      if (useNotSpecify) {
        const rule = get(notSpecifyRulesConfig, `${notSpecifyRule}.label`)
        return { content: `分配規則預設為「${rule}」如需修改請至基本參數設定 > 預約模組參數設定設置`, color: 'var(--base)' }
      } else if (!useNotSpecify) {
        if (selectUnitId === 'all') return { content: '分配規則預設為「依排序大小」如需修改請至基本參數設定 > 預約模組參數設定設置', color: 'var(--base)' }
        else if (selectUnitId === 'notSpecify') return { content: '未啟用不指定人員設定，請至基本參數設定 > 預約模組參數設定設置', color: 'var(--danger)' }
      }
      return { content: '', color: 'var(--base)' }
    })

    const disabledInput = computed(() => {
      const base = {
        serviceType: false,
        serviceUnit: false,
        service: false,
        attachService: false,
        totalBookingTime: false,
        isOverTime: false,
      }
      let disableds = []
      const useNotSpecify = get(props.config, 'reservation.useNotSpecify')
      const selectUnitId = get(formData.serviceUnit, 'id')
      if (!useNotSpecify && selectUnitId === 'notSpecify') disableds = ['service', 'attachService', 'totalBookingTime', 'isOverTime']
      setValues(base, disableds, true)
      return base
    })

    const formData = reactive({
      otherService: false,
      service: null,
      serviceDetail: null,
      subService: null,
      attachServices: null,
      serviceUnit: null,
      totalTime: 0,
      resourceItemId: null,
      resourceTotalTime: 0,
      isOverTime: false,
      date: '',
      time: '',
      type: 'humanService',
      hasPartner: false,
    })

    const formRules = computed(() => {
      return {
        type: [noEmptyRules()],
        service: [noEmptyRules()],
        subService: [noEmptyRules()],
        serviceUnit: [noEmptyRules()],
        date: [noEmptyRules()],
        time: [noEmptyRules()],
        resourceItemId: [noEmptyRules()],
        resourceTotalTime: [noEmptyRules()],
      }
    })
    const resourceItemList = computed(() => {
      const subService = get(mainServiceData.value, 'AppointmentSubServices')
      const result = filter(get(mainServiceData.value, 'ResourceItems'), (i) => i.enabled && !i.isRemove)
      if (subService && formData.subService) {
        if (get(subService, 'allResourceItem')) return result
        const subResourceItems = get(formData.subService, 'ResourceItems')
        if (isEmpty(subResourceItems)) return result
        else return filter(result, i => find(subResourceItems, { id: i.id }) && i.enabled && !i.isRemove)
      }
      return result
    })

    const showResourceService = computed(() => {
      return includes(['resourceService', 'humanAndResourceService'], formData.type)
    })
    const totalTime = computed(() => {
      const form = { ...formData }
      if (!form.service) return 0
      let base = 0

      if (!form.subService) base += form.service.bookingTime
      if (form.subService) base += form.subService.bookingTime

      let attach = 0
      if (form.attachServices) {
        form.attachServices.forEach((item) => {
          attach += item.bookingTime
        })
      }
      console.log(base, attach)
      return base + attach
    })

    const serviceTypeChange = async () => {
      formData.otherService = false
      formData.service = null
      formData.serviceDetail = null
      formData.subService = null
      formData.attachServices = null
      formData.serviceUnit = null
      formData.totalTime = 0
      formData.resourceItemId = null
      formData.resourceTotalTime = 0
      formData.isOverTime = false
      formData.date = null
      formData.time = null
    }
    const filterServiceOptions = computed(() => {
      let serviceOptionList = []
      if (checkAction('admin.resourceItem.page')) {
        if (formData.type === 'resourceService') {
          serviceOptionList = filter(serviceOptions.value, (i) => !i.enableAppointmentUnit && i.enableResourceItem)
        } else if (formData.type === 'humanService') {
          serviceOptionList = filter(serviceOptions.value, (i) => i.enableAppointmentUnit && !i.enableResourceItem)
        } else if (formData.type === 'humanAndResourceService') {
          serviceOptionList = filter(serviceOptions.value, (i) => i.enableAppointmentUnit && i.enableResourceItem)
        } else {
          serviceOptionList = serviceOptions.value
        }
      } else {
        serviceOptionList = serviceOptions.value
      }

      return serviceOptionList
    })

    const getServiceUnit = async () => {
      fetchAllOld(GetServiceUnit, { shopId: shopId.value }, (res) => {
        console.log('✅ res', res)
        allServiceUnitList.value = res
      })
    }

    const findServiceUnit = async (unitId) => {
      try {
        const res = await FindServiceUnit({
          shopId: shopId.value,
          id: unitId,
        })
        serviceOptions.value = sortOrder(res.AppointmentServices)
      } catch (error) {
        console.log(error)
        window.$message.error(error)
      }
    }

    const serviceUnitChange = (serviceUnit) => {
      if (includes(['notSpecify', 'all'], serviceUnit.id)) {
        serviceOptions.value = unref(allServiceList.value)
      } else {
        findServiceUnit(serviceUnit.id)
      }

      // if (showResourceService.value) {
      //   serviceOptions.value = filter(serviceOptions.value, (i) => i.enableResourceItem)
      // }

      formData.totalTime = 0
      formData.service = ''
      formData.subService = null
      formData.attachServices = []
      formData.date = ''
      formData.time = ''
    }

    onMounted(async () => {
      console.log('here')
      getServiceUnit()
      if (!allServiceList.value.length) {
        fetchAllOld(GetService, { shopId: shopId.value }, (res) => {
          allServiceList.value = res
          serviceOptions.value = unref(allServiceList.value)
        })
      }
    })

    return {
      formData,
      formRules,
      serviceUnitIOptions,
      allServiceUnitList,
      serviceUnitChange,
      allServiceList,
      // serviceChange,
      mainServiceData,
      serviceOptions,
      showResourceService,
      serviceTypeChange,
      totalTime,
      resourceItemList,
      serviceUnitSelectHint,
      disabledInput,
      filterServiceOptions,
      genNums,
      getResourceNums,
    }
  },
  data () {
    return {
      availableTimes: [],
      availableDate: [],

      pickerOptions: {
        disabledDate: (date) => {
          if (this.formData.isOverTime) return false
          const d = dayjs(date).format('YYYY/MM/DD')
          return !this.availableDate.find((item) => item === d)
        },
      },
      serviceTypeConfig,
    }
  },

  computed: {
    ...mapGetters([
      'shop',
      'shopTimeUnit',
      'userFeatures',
      'userPlanFeature']),

    subServiceList () {
      if (!this.mainServiceData) return []
      else if (get(this.formData, 'type') === 'resourceService') return get(this.mainServiceData, 'AppointmentSubServices')
      else if (includes(['notSpecify', 'all'], get(this.formData, 'serviceUnit.id'))) return get(this.mainServiceData, 'AppointmentSubServices')
      else return get(this.mainServiceData, 'AppointmentSubServices').filter(s => find(s.AppointmentUnits, { id: get(this.formData, 'serviceUnit.id') }) || isEmpty(s.AppointmentUnits)) // or 子項目沒綁定人員
    },
    enableSubService () {
      return get(this.mainServiceData, 'enableSubService')
    },
    enableResourceItem () {
      return get(this.mainServiceData, 'enableResourceItem')
    },
    checkResource () {
      return this.checkAction('admin.resourceItem.page')
    },
    showHumanService () {
      return this.formData.type === 'humanService' || this.formData.type === 'humanAndResourceService'
    },

    showHumanAndResourceService () {
      return this.formData.type === 'humanAndResourceService'
    },
    showServiceTypeConfig () {
      const omitList = []
      if (this.peopleCount > 1 || !this.checkAction('admin.resourceItem.page')) omitList.push('resourceService', 'humanAndResourceService')
      return omit(this.serviceTypeConfig, omitList)
    },
  },
  watch: {
    formData: {
      handler: function (data) {
        return this.$emit('update', data)
      },
      deep: true,
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.$emit('ref', this.$refs.form)
    })
  },
  methods: {
    checkAction (action) {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },
    getData (obj, path, defaultValue) {
      return get(obj, path, defaultValue)
    },
    genTimes () {
      const offset = this.config.reservation.timeUnitOffset
      const timeUnit = this.config.reservation.timeUnit
      return generateTimes(timeUnit, offset)
    },

    async getDate () {
      this.$nextTick(async () => {
        let dateStart
        let dateEnd
        if (this.$refs.picker.picker) {
          const year = this.$refs.picker.picker.year
          const month = this.$refs.picker.picker.month
          const { start, end } = getMonth(year, month + 1)
          dateStart = start
          dateEnd = end
        } else {
          const { start, end } = thisMonth()
          dateStart = start
          dateEnd = end
        }

        // await this.getAvailableDate(dateStart, dateEnd)
        await this.getAvailableTimes({ start: dayjs(dateStart), end: dayjs(dateEnd) })
        // if (this.pickerAddEvent) return

        const pre = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-arrow-left',
        )
        const next = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-arrow-right',
        )
        const preYear = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-d-arrow-left',
        )
        const nextYear = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-d-arrow-right',
        )

        const task = async () => {
          this.availableDate = []

          const year = this.$refs.picker.picker.year
          const month = this.$refs.picker.picker.month
          const { start, end } = getMonth(year, month + 1)

          // await this.getAvailableDate(start, end)
          await this.getAvailableTimes({ start: dayjs(start), end: dayjs(end) })
        }
        pre.removeEventListener('click', task)
        next.removeEventListener('click', task)
        preYear.removeEventListener('click', task)
        nextYear.removeEventListener('click', task)

        pre.addEventListener('click', task)
        next.addEventListener('click', task)
        preYear.addEventListener('click', task)
        nextYear.addEventListener('click', task)
        // this.pickerAddEvent = true
      })
    },
    async getAvailableTimes ({ start, end }) {
      let appointmentUnitIds = get(this.formData, 'serviceUnit.id') ? [get(this.formData, 'serviceUnit.id')] : null
      // const useNotSpecify = get(this.configData, 'useNotSpecify')
      if (appointmentUnitIds) {
        if (appointmentUnitIds.includes('all')) appointmentUnitIds = null
        else if (appointmentUnitIds.includes('notSpecify')) appointmentUnitIds = map(get(this.config, 'reservation.NotSpecifyUnits'), 'id')
      }

      console.log('NotSpecifyUnits', get(this.config, 'reservation.NotSpecifyUnits'))

      const [res] = await GetAvailableTimes({
        shopId: this.shop,
        rangeStart: start.toDate(),
        rangeEnd: end.toDate(),
        appointmentUnitIds,
        resourceItemIds: this.formData.resourceItemId ? [get(this.formData.resourceItemId, 'id')] : null,
        appointmentServiceId:
          get(this.formData, 'service.id') || undefined,
        appointmentSubServiceId: get(this.formData, 'subService.id'),
        appointmentServiceAttachIds: map(
          get(this.formData, 'attachServices', []),
          'id',
        ),
        setTotalBookingTime: this.formData.totalTime,
        setResourceItemTotalBookingTime: this.formData.resourceTotalTime,
        peopleCount: this.peopleCount,
      })

      const list = []
      res.times.forEach((item) => {
        list.push(dayjs(item).format('YYYY/MM/DD'))
      })
      this.availableDate = Array.from(new Set(list))
      this.availableTimes = filter(res.times, (item) => {
        return dayjs(item).isSame(this.formData.date, 'date')
      })
      // this.availableTimes = filter(res.times, (item) => {
      //   return dayjs(item).isAfter(dayjs(), 'h')
      // })
    },

    dateChange (date) {
      console.log('dateChange')
      const start = dayjs(date).startOf('d')
      const end = dayjs(date).add(1, 'd').startOf('d')
      this.formData.time = ''
      this.getAvailableTimes({
        start: dayjs(start),
        end: dayjs(end),
      })
    },
    updateTimeOptions () {
      this.genNums = generateNumbers(0, 600, this.shopTimeUnit)
      this.genNums.push(this.totalTime)
      this.genNums = [...new Set(this.genNums.sort((a, b) => a - b))]
      this.getResourceNums = generateNumbers(0, this.formData.totalTime + this.shopTimeUnit, this.shopTimeUnit)
      this.getResourceNums.push(this.totalTime)
      this.getResourceNums = [...new Set(filter(this.getResourceNums.sort((a, b) => a - b), (item) => item <= this.formData.totalTime))]
    },

    subServiceChange (service) {
      this.formData.subService = service
      this.formData.totalTime = this.totalTime
      this.formData.resourceTotalTime = this.totalTime
      this.updateTimeOptions()
      this.formData.resourceItemId = null
      this.formData.attachServices = []
      this.formData.date = ''
      this.formData.time = ''
    },

    async serviceChange (service) {
      console.log('servicechange')
      this.findServiceDetail()
      this.formData.attachServices = []
      this.formData.subService = null
      this.formData.resourceItemId = null
      this.formData.totalTime = this.totalTime
      this.formData.resourceTotalTime = this.totalTime
      this.formData.date = ''
      this.formData.time = ''
      this.updateTimeOptions()
    },
    async findServiceDetail  () {
      const [res, err] = await FindService({
        shopId: this.shop,
        id: this.formData.service.id,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      this.mainServiceData = res
      this.formData.serviceDetail = res
    },

    totalTimeChange () {
      if (this.formData.isOverTime) return
      this.formData.date = ''
      this.formData.resourceTotalTime = this.formData.totalTime
      this.updateTimeOptions()
    },

    attachServiceChange () {
      this.formData.date = ''
      this.formData.time = ''
      this.formData.totalTime = this.totalTime
      this.formData.resourceTotalTime = this.totalTime
      this.updateTimeOptions()
    },
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-form-item__label {
  @apply flex
}
</style>
