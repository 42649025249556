<template>
  <el-select
    v-model="syncModel"
    filterable
    remote
    clearable
    reserve-keyword
    :placeholder="placeholder || '請輸入身分證後4碼+民國年月日'"
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    @change="onChange"
    @clear="getSubsidyMembers(), $emit('clear')"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <el-option
      v-for="item in subsidyMembersList"
      :key="item.id"
      :label="`${item.name} (${item.birthday})`"
      :value="item"
    >
      {{ `${item.name} (${item.identityCard}${item.birthday})` }}
    </el-option>
  </el-select>
</template>
<script>
import {GetSubsidyMembers} from '@/api/subsidyMember'
import {mapGetters} from 'vuex'
import {get} from 'lodash'

export default {
  name: 'SubsidyMemberSearch',
  props: ['model', 'type', 'placeholder'],

  computed: {
    ...mapGetters(['shop']),
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },
  // :value="{name:item.UserInfo.name, phone:item.UserInfo.phone}"

  data: () => ({
    loading: false,
    subsidyMembersList: [],
  }),

  async mounted () {
    await this.getSubsidyMembers()
    if (get(this.model, 'id')) this.subsidyMembersList.push(this.model)
  },

  methods: {
    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.subsidyMembersList = []

      try {
        this.loading = true
        setTimeout(async () => {
          await this.getSubsidyMembers(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    onChange (member) {
      this.$emit('change', member)
    },

    async getSubsidyMembers (query = null) {
      try {
        this.subsidyMembersList = await GetSubsidyMembers({
          shopId: this.shop,
          name: query || undefined,
          memberId: query || undefined,
          limit: 50,
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="postcss">
/* ::v-deep .el-icon-::before {
  content: '\e778';
} */

::v-deep.el-select .el-input .el-select__caret {
  @apply transform rotate-0;
}
</style>
