import { admin, admin2 } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/subsidyMember`
}

//= > 取得會員
export const GetSubsidyMembers = async ({
  shopId,
  start,
  limit,
  name,
  memberId,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}`,
    params: {
      start,
      limit,
      name,
      // memberId
    },
  })

  return res.data
}

//= > 取得會員總數
export const GetSubsidyMembersCount = async ({
  shopId,
  name,
  memberId,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
      memberId
    },
  })

  return res.data.count
}

//= > 查找指定會員
export const FindSubsidyMember = async ({
  shopId,
  id,
  phone
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
    params: {
      phone,
    },
  })

  return res.data
}

//= > 驗證資料
export const VerifySubsidyMember = async ({
  shopId,
  identityCard,
  birthday,
}) => {
  const res = await admin({
    method: 'post',
    url: `${baseUrl(shopId)}/verify`,
    data: {
      identityCard,
      birthday,
    },
  })

  return res.data
}

//= > 驗證大量匯入資料
export const VerifyBatchImportSubsidyMember = async (shopId, data) => {
  const res = await admin({
    method: 'post',
    url: `${baseUrl(shopId)}/batchImport/verify`,
    data,
  })

  return res.data
}

//= > 大量匯入資料
export const BatchImportSubsidyMember = async (shopId, data) => {
  const res = await admin({
    method: 'post',
    url: `${baseUrl(shopId)}/batchImport`,
    data,
  })

  return res.data
}
