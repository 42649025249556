import { get } from 'lodash'
import { GetDepositConfig, GetDepositPayment } from '@/api/deposit'
import { FindReservationParameter } from '@/api/reservation'
import { useFetch } from '@/use/fetch'
import { reactive, computed } from 'vue'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'

const featureKeys = {
  useDeposit: 'adminView.appointmentDeposit.enable',
  useClassTicket: 'admin.classTicket.page',
}

export const useReservation = () => {
  const { simpleFetch } = useFetch()
  const { shopId } = useShop()
  const { checkAction } = usePermissions()

  const configData = reactive({
    deposit: {},
    depositPayments: {},
    reservation: {},
  })

  // enabled block
  const enabledBlocks = computed(() => ({
    deposit: enabledDepositBlock.value,
    serviceClassTicket: enabledServiceClassTicketBlock.value,
  }))
  const enabledDepositBlock = computed(() => {
    if (!get(configData.deposit, 'enable')) return false
    if (!checkAction(featureKeys.useDeposit)) return false
    return true
  })

  const enabledServiceClassTicketBlock = computed(() => {
    // if (!get(configData.deposit, 'enableClassTicketCredit')) return false
    if (!checkAction(featureKeys.useClassTicket)) return false
    return true
  })

  // show block
  const showBlocks = computed(() => ({
    deposit: showDepositBlock.value,
    serviceClassTicket: showServiceClassTicketBlock.value,
  }))
  const showDepositBlock = computed(() => {
    if (!enabledDepositBlock.value) return false
    return true
  })

  const showServiceClassTicketBlock = computed(() => {
    if (!enabledServiceClassTicketBlock.value) return false
    return true
  })

  const getAllConfig = async () => {
    const basePayload = { shopId: shopId.value }

    if (checkAction(featureKeys.useDeposit)) {
      simpleFetch(GetDepositConfig, basePayload, (res) => { configData.deposit = res })
      simpleFetch(GetDepositPayment, basePayload, (res) => { configData.depositPayments = res })
    }

    const res = await FindReservationParameter(basePayload)
    configData.reservation = res
  }

  return { getAllConfig, showDepositBlock, showServiceClassTicketBlock, configData, showBlocks, enabledBlocks }
}
