import { get, filter, map, sortBy, intersection, uniq } from 'lodash'
import dayjs from 'dayjs'

export const useReservationClassTicket = () => {
  const mappingServiceClassTickets = (service, memberClassTickets) => {
    if (!service) return []
    const serviceClassTicketIds = map(get(service, 'ClassTickets'), 'id')
    return filter(memberClassTickets, (ticket) => serviceClassTicketIds.includes(get(ticket, 'ClassTicketId')))
  }

  const defaultUseServiceClassTicket = (service, reservationTime, memberClassTickets) => {
    if (!get(service, 'enableClassTicket')) return null
    if (!get(service, 'requiredClassTicket')) return null

    const tickets = mappingServiceClassTickets(service, memberClassTickets)
    // const availableTickets = sortBy(tickets, 'exp')
    const availableTickets = sortClassTickets(filter(tickets, (ticket) => checkClassTicketForReservationTime(reservationTime, ticket)))
    return get(availableTickets, '[0]')
  }

  /** 過濾會員針對該服務適用的堂票 */
  const filterMemberAvailableServiceClassTickets = (service, memberClassTickets) => {
    if (!service) return false
    const serviceClassTickets = map(service.ClassTickets, 'id')
    const classTickets = map(memberClassTickets, 'ClassTicketId')
    const tickets = intersection(serviceClassTickets, uniq(classTickets))
    if (!tickets.length) return false
    return tickets
  }

  /** 檢查堂票過期時間是否小於預約時段 */
  const checkClassTicketForReservationTime = (reservationTime, classTicket) => {
    if (!classTicket.isExp) return classTicket
    const willNotExp = dayjs(classTicket.exp).isAfter(dayjs(reservationTime))
    if (willNotExp) return classTicket
  }

  /** 檢查堂票過期時間是否小於預約時段 */
  const sortClassTickets = (classTicketRecords) => {
    return classTicketRecords.sort((a, b) => {
      const aExp = dayjs(a.exp)
      const bExp = dayjs(b.exp)
      if (aExp.isBefore(bExp)) return 1
      else return -1
    })
  }

  return {
    defaultUseServiceClassTicket,
    filterMemberAvailableServiceClassTickets,
    checkClassTicketForReservationTime,
    sortClassTickets,
  }
}
